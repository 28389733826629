import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import { start } from 'single-spa'
import { initAuthClient } from '@toasttab/frontend-authentication'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import {
  devTools,
  registerDeveloperPortalApps,
  registerUserVerificationSpa
} from '../registration'
import { getGlobalCustomProps } from '../utils'
import { getApolloClient } from '../api/ApolloClient'

// import { setupSentry } from '../utils'

// This causes borks with sentry v4 version in other SPAs
// setupSentry({
//   publicKey: '464d20e84eef41f987e0f56315e19c6a@o37442',
//   projectId: '5758707'
// })

// REMOVE ONCE WE UPDATE TO REACT 17.0.2
// Reference: https://github.com/facebook/create-react-app/issues/10474
//@ts-ignore
if (!window.crossOriginIsolated) window.SharedArrayBuffer = ArrayBuffer

const apolloClient = getApolloClient()
const env = getCurrentEnvironment()
export const getCurrentPathname = () => window.location.pathname

if (getCurrentPathname().includes('/user-verification')) {
  devTools()
  registerUserVerificationSpa()
} else {
  try {
    initAuthClient({
      originsWithAuth: [window.location.origin],
      environment: env,
      application: 'DEPOT'
    }).then(async ({ auth }) => {
      const globalProps = await getGlobalCustomProps(auth, apolloClient)
      devTools(globalProps)
      registerDeveloperPortalApps(globalProps)
    })
  } catch (e) {
    console.error(e)
  }
}
start({ urlRerouteOnly: true })
