import { registerApplication } from 'single-spa'
import { RegistrationByNameFunc } from './registration'
import { spaList, spaRoutes } from './spaConfig'
import { importSpa, matchRoute } from '../utils'
import { DepotCustomProps } from '../../packages/depot-banquet-props/src'

export const registerSpa: RegistrationByNameFunc = (
  spaName: keyof typeof spaList,
  customProps?: DepotCustomProps
) => {
  const name = spaList[spaName]
  registerApplication({
    name,
    activeWhen: ({ pathname }) =>
      matchRoute(spaRoutes[name], Object.values(spaRoutes), pathname, true),
    customProps: customProps,
    app: importSpa(name)
  })
}
